import React, { useEffect, useState } from "react";
import "./Ads.scss";
import Button from "../../Components/Button/Button";
import Table from "../../Components/TableComponents/Table/Table";
import TableHeader from "../../Components/TableComponents/TableHeader/TableHeader";
import Row from "../../Components/TableComponents/Row/Row";
import TableBody from "../../Components/TableComponents/TableBody/TableBody";
import edit_icon from "../../assets/images/edit.svg";
import trash_icon from "../../assets/images/delete.svg";
import RadioButtonGroup from "../../Components/RadioButtonGroup/RadioButtonGroup";
import Switch from "../../Components/Switch/Switch";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  editAdAction,
  getAdByIdAction,
  getAdsAction,
} from "../../Redux/Ads/AdsActions";
import { useSelector } from "react-redux";
import DeleteAd from "./AdsActions/DeleteAd/DeleteAd";
import Loader from "../../Components/Loader/Loader";
function Ads() {
  const {
    deleteAdSuccess,
    deleteAdLoading,
    createAdLoading,
    editAdLoading,
    getAdsLoading,
    getAdByIdLoading,
  } = useSelector((state) => state.adsReducer);
  const { ads } = useSelector((state) => state.adsReducer.getAdsData);
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdsAction());
  }, []);
  const data = [
    {
      AdLink: "https://www.google.com/",
      Tier: "Tier 1",
      Impressions: 100,
      Clicks: 20,
      Views: 5,
      Status: "Active",
      Actions: "Edit",
    },
  ];

  const handleEdit = (id) => {
    dispatch(getAdByIdAction(id?.id));
    Navigate("/ads/edit-ad");
  };
  const [popups, setPopups] = useState({
    deletePopup: false,
  });
  const [adId, setAdId] = useState("");

  const handleDelete = (id) => {
    setPopups({ ...popups, deletePopup: true });
    setAdId(id);
  };

  useEffect(() => {
    if (deleteAdSuccess) {
      setPopups({ ...popups, deletePopup: false });
    }
  }, [deleteAdSuccess]);

  const [checked, setChecked] = useState("");
  const handleChangeStatus = () => {
    setChecked(!checked);
  };
  return (
    <div className="Ads">
      {(getAdsLoading ||
        getAdByIdLoading ||
        deleteAdLoading ||
        editAdLoading ||
        createAdLoading ||
        deleteAdLoading) && <Loader fullScreen={true}></Loader>}
      <div className="title-btn">
        <div className="ads_title">Advertisments</div>
        <Button main={true} onClick={() => Navigate("/ads/create-ad")}>
          Create New Ad
        </Button>
      </div>
      <div className="ads-container">
        <div className="ads-table-title">Advertisments</div>
        <div className="ads-table">
          <Table>
            <TableHeader>
              <Row>
                <th>Ad Link</th>
                <th>Tier</th>
                <th>Impressions</th>
                <th>Clicks</th>
                <th>Views</th>
                <th>Status</th>
                <th>Actions</th>
              </Row>
            </TableHeader>
            <TableBody>
              {ads?.map((ad, index) => {
                return (
                  <Row>
                    <td> {ad.link}</td>
                    <td> {ad.Tier}</td>
                    <td> {ad.impressions}</td>
                    <td> {ad.clicks}</td>
                    <td> {ad.viewers}</td>
                    <td>
                      {" "}
                      <Switch
                        checked={ad?.status}
                        setChecked={() =>
                          dispatch(
                            editAdAction(ad?.id, { status: !ad?.status })
                          )
                        }
                      ></Switch>
                    </td>
                    <td className="actions">
                      <img
                        src={edit_icon}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleEdit(ad)}
                      ></img>
                      <img
                        src={trash_icon}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDelete(ad?.id)}
                      ></img>
                    </td>
                  </Row>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>
      <DeleteAd
        show={popups.deletePopup}
        setShowPopup={setPopups}
        id={adId}
      ></DeleteAd>
    </div>
  );
}

export default Ads;

import React, { useState, useEffect } from "react";
import "./RadioButtonGroup.scss";

function RadioButton({ data, name, isChecked, handleOnClick, disabled }) {
  const a = Math.random();
  return (
    <span className="radio_container">
      <input
        type="radio"
        id={a}
        name={name}
        checked={isChecked}
        style={{
          display: "none",
        }}
        onChange={handleOnClick}
        disabled={disabled}
      />
      <div className={isChecked && "active_button_container"}>
        <div
          className={isChecked ? "radio_button active" : "radio_button"}
          style={{ cursor: !disabled ? "pointer" : "default" }}
          onClick={handleOnClick}
        ></div>
      </div>
      <label htmlFor={a} className={disabled && "radio_button_title_disabled"}>
        {data}
      </label>
      <br />
    </span>
  );
}

function RadioButtonGroup({
  options,
  name,
  setSelectedRadio,
  selectedRadio,
  disabled,
}) {
  const [selectedOption, setSelectedOption] = useState(selectedRadio);

  useEffect(() => {
    setSelectedOption(selectedRadio);
  }, [selectedRadio]);

  const handleOnClick = (option) => {
    if (!disabled) {
      setSelectedOption(option);
      setSelectedRadio(option); // call the callback with the selected option
    }
  };

  return (
    <>
      {options.map((option) => (
        <RadioButton
          key={option}
          data={option}
          name={name}
          isChecked={selectedOption === option}
          handleOnClick={() => handleOnClick(option)}
          disabled={disabled}
        />
      ))}
    </>
  );
}

export default RadioButtonGroup;

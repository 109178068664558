import { axiosReq } from "../../utils";
import { authActions } from "./authReducer";

//write actions in here like this

// export const getUser = () => async (dispatch) => {
//   dispatch(getUserRequest());
//   dispatch(
//     axiosReq(
//       "get",
//       process.env.REACT_APP_API + "/user/me",
//       {},
//       {
//         body,
//       },
//       {}
//     )
//   ).then((res) => {
//     if (res.success) {
//       dispatch(getUserSuccess(res.data));
//     } else {
//       dispatch(getUserError(res.data?.message));
//     }
//   });
// };

export const login = (body) => async (dispatch) => {
  dispatch(authActions.loginRequest());
  dispatch(
    axiosReq(
      "post",
      process.env.REACT_APP_API + "/auth/login",
      {},
      {
        ...body,
      },
      {}
    )
  ).then((res) => {
    if (res.success) {
      dispatch(authActions.loginSuccess(res?.data?.data));
    } else {
      dispatch(authActions.loginError(res.data?.message));
    }
    dispatch(clearAction());
  });
};

export const getMe = (navigate) => async (dispatch) => {
  dispatch(authActions.getUserRequest());
  dispatch(
    axiosReq("get", process.env.REACT_APP_API + "/user/getMe", {}, {}, {})
  ).then((res) => {
    if (res.success) {
      dispatch(authActions.getUserSuccess(res?.data?.data));
    } else {
      dispatch(authActions.getUserError(res.data?.message));
      navigate("/");
    }
    dispatch(clearAction());
  });
};

export const forgetPassword = (email) => async (dispatch) => {
  dispatch(authActions.forgetPasswordRequest());
  dispatch(
    axiosReq(
      "patch",
      process.env.REACT_APP_API + "/auth/forgetPassword",
      {},
      {
        email,
      },
      {}
    )
  ).then((res) => {
    if (res.success) {
      dispatch(authActions.forgetPasswordSuccess());
    } else {
      dispatch(authActions.forgetPasswordError(res.data?.message));
    }
    dispatch(clearAction());
  });
};

export const clearAction = () => async (dispatch) => {
  setTimeout(() => {
    dispatch(authActions.clear());
  }, 3000);
};

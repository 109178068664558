import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import authReducer from "./Redux/Auth/authReducer";
import AdsReducer from "./Redux/Ads/AdsReducer";

const RootReducer = combineReducers({
  authReducer: authReducer,
  adsReducer: AdsReducer
});

export const Store = configureStore({
  reducer: RootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: () => [thunk],
});

import React, { useEffect, useRef, useState } from "react";
import "./CustomDropDown.scss";
import arrow from "../../assets/images/arrow.svg";
import gsap from "gsap";

function CustomDropDown({
  data,
  placeholder,
  style,
  value,
  setValue,
  title,
  disabled,
  icon,
  box,
  noBorder,
}) {
  const [openMenu, setOpenMenu] = useState(false);

  const [search, setSearch] = useState();
  const [newData, setNewData] = useState([]);

  const inputRef = useRef(null);
  const customDropdownRef = useRef(null);

  useEffect(() => {
    setNewData(data);
  }, [data]);
  useEffect(() => {
    if (customDropdownRef.current) {
      const arrowIcon = customDropdownRef.current.querySelector(".arrow_icon");
      const dropdownMenu = customDropdownRef.current.querySelector(
        ".dropdown_menu_main"
      );

      if (openMenu) {
        setNewData(data);
        gsap.to(arrowIcon, { duration: 0.2, rotation: 180 });
        gsap.to(dropdownMenu, { duration: 0.2, opacity: 1 });
      } else {
        inputRef.current.blur();
        gsap.to(arrowIcon, { duration: 0.2, rotation: 0 });
        gsap.to(dropdownMenu, { duration: 0.2, opacity: 0 });
      }
    }
  }, [openMenu]);

  useEffect(() => {
    if (value) {
      setSearch(value.label);
    }
  }, [value]);

  useEffect(() => {
    if (search && search != "") {
      setNewData(
        data.filter((element) =>
          element.label.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setNewData(data);
    }
  }, [search]);

  return (
    <div
      className={
        !box
          ? "custom_dropdown_container"
          : "custom_dropdown_container custom_dropdown_container_box"
      }
      style={style}
      onClick={() => inputRef.current.focus()}
      ref={customDropdownRef}
    >
      {title && (
        <div className="custom_dropdown_header">
          <img src={icon} className="custom_dropdown_icon" />
          <div
            className={
              !disabled
                ? "custom_dropdown_title"
                : "custom_dropdown_title disabled_title"
            }
          >
            {title}
          </div>
        </div>
      )}
      <div
        className={
          !disabled
            ? "custom_dropdown_main"
            : `custom_dropdown_main ${!noBorder ? "disabled_dropdown" : ""}`
        }
        style={{ ...(openMenu && { borderColor: "var(--accent-dark)" }) }}
        onClick={() => {
          !disabled && data?.length > 0 && setOpenMenu(!openMenu);
        }}
      >
        <input
          className={
            value != null
              ? "custom_dropdown_main_text"
              : "custom_dropdown_main_text grey_text"
          }
          placeholder={placeholder}
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          ref={inputRef}
          disabled={disabled}
        />

        <img src={arrow} className="arrow_icon" />
      </div>
      {openMenu && (
        <div className="custom_dropdown_menu">
          <div className="background" onClick={() => setOpenMenu(false)}></div>
          <div className="custom_dropdown_menu_container">
            <div className="custom_dropdown_menu_main">
              {newData.map((element, index) => {
                return (
                  <div
                    className="single_item"
                    style={{
                      color:
                        element?.value === value?.value &&
                        element?.label === value?.label &&
                        "var(--accent)",
                    }}
                    key={index}
                    onClick={() => {
                      setValue(element);
                      setOpenMenu(false);
                    }}
                  >
                    <div className="text">{element?.label}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CustomDropDown;

import React, { useEffect, useState } from 'react'
import CustomPopup from '../../../../Components/CustomPopup/CustomPopup'
import Button from '../../../../Components/Button/Button'
import "./DeleteAd.scss"
import { deleteAdAction } from '../../../../Redux/Ads/AdsActions'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

function DeleteAd({show,setShow,id}) {
  const [error, setError, deleteAdError] = useState("")

  const {deleteAdLoading} = useSelector(state => state.adsReducer)
    const dispatch = useDispatch()
    const handleDelete = () => {
        dispatch(deleteAdAction(id))
        setError(deleteAdError)
    }

    useEffect(() => {
        setError("")
    },[show])
  return (
    <CustomPopup show={show} setShowPopup={setShow} title={"Delete Ad"}>
<div className='deleteAd'>
        <div className="deleteAdMessage">Are You Sure You want to delete this Ad?</div>
        <div className="deleteAd-btns">
              <Button main={true} width={"50%"} onClick={()=>{handleDelete()}} loading={deleteAdLoading}>Delete</Button>
              <Button main={false} width={"50%"} onClick={()=>setShow(false)} loading={deleteAdLoading}>Cancel</Button>
        </div>
        {error && <div className='error'>{error}</div>}
        </div>
    </CustomPopup>
  )
}

export default DeleteAd
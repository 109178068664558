import { AdsActions } from "./AdsReducer";
import { axiosReq } from "../../utils";

export const getAdsAction = () => async (dispatch) => {
    dispatch(AdsActions.getAdsRequest());
    try {
        const response = await fetch(
            process.env.REACT_APP_API + "/ads/getAllAds",
        );
        const data = await response.json();
        dispatch(AdsActions.getAdsSuccess(data));
    } catch (error) {
        dispatch(AdsActions.getAdsError(error));
    }
    dispatch(clearStatesAction());
}

export const createAdAction = (data) => async (dispatch) => {
  
    const formdata = new FormData();
    //loop over data and apapend keys
    for (const key in data) {
          formdata.append(key, data[key]);
  }
    dispatch(AdsActions.createAdRequest());
    dispatch(
      axiosReq("post", process.env.REACT_APP_API + "/ads/createAd", {}, formdata, {})
    ).then((res) => {
      if (res.success) {
        dispatch(AdsActions.createAdSuccess(res?.data?.data));
      } else {
        dispatch(AdsActions.createAdError(res.data?.message));
      }
    });
  };

  export const editAdAction = (id, data) => async (dispatch) => {

    const formdata = new FormData();
    for (const key in data) {
      formdata.append(key, data[key]);
    }
    dispatch(AdsActions.editAdRequest());
    dispatch(
      axiosReq("patch", process.env.REACT_APP_API + `/ads/updateAd/${id}`, {}, formdata, {})
    ).then((res) => {
      if (res.success) {
        dispatch(AdsActions.editAdSuccess(res?.data?.data));
        dispatch(getAdsAction())
      } else {
        dispatch(AdsActions.editAdError(res.data?.message));
      }
      dispatch(clearStatesAction());
    });
  
  };
  
  export const getAdByIdAction = (id) => async (dispatch) => {
    dispatch(AdsActions.getAdByIdRequest());
    try {
      const response = await fetch(
        process.env.REACT_APP_API + `/ads/getAdById/${id}`,
      );
      const data = await response.json();
      dispatch(AdsActions.getAdByIdSuccess(data));
    } catch (error) {
      dispatch(AdsActions.getAdByIdFailure(error));
    }
    dispatch(clearStatesAction());
  }
  
  export const deleteAdAction = (id) => async (dispatch) => {
    dispatch(AdsActions.deleteAdRequest());
    dispatch(
      axiosReq("delete", process.env.REACT_APP_API + `/ads/deleteAd/${id}`, {}, {}, {})
    ).then((res) => {
      if (res.success) {
        dispatch(AdsActions.deleteAdSuccess(res?.data?.data));
        dispatch(getAdsAction());
      } else {
        dispatch(AdsActions.deleteAdError(res.data?.message));
      }
      dispatch(clearStatesAction());
    });
  }

  const clearStatesAction = () => async (dispatch) => {
    setTimeout(() => {
      dispatch(AdsActions.clearAllStates());
    }, 3000);
   
  }
import React, { useEffect, useState } from 'react'
import { editAdAction } from '../../../../Redux/Ads/AdsActions'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import bag from '../../../../assets/images/shopping-bag.svg'
import Button from '../../../../Components/Button/Button'
import FileInput from '../../../../Components/FileInput/FileInput'
import Input from '../../../../Components/Input/Input'
import './EditAd.scss'
import Loader from '../../../../Components/Loader/Loader'
import DropDown from '../../../../Components/DropDown/DropDown'


function EditAd() {
    const ad = useSelector(state => state.adsReducer.adById.ad)
    const {editAdSuccess,editAdLoading, editAdError} = useSelector(state => state.adsReducer)
    const dispatch = useDispatch()
  
    const [Ad, setAd] =useState({
      link: "",
      Tiers: "",
      banner_image: "",
      image: "",
      status: null,
    })
    const [status, setStatus] = useState({
      value: "",
      label: "",
    })
  const [error, setError] = useState("")
    const handleEdit = () => {
      dispatch(editAdAction(ad?.id,{link:Ad.link, banner_image: Ad.banner_image, image:Ad.image, status:Ad.status?.value} ))
      setError(error)
      if(editAdSuccess) {
        setAd({
          link: "",
          banner_image: "",
          image: "",
        })
      } 
  }
useEffect(()=> {
setError("")
},[])
  useEffect(() => {
    setAd({...Ad, link: ad?.link, banner_image: ad?.banner_image, image: ad?.image, status: ad?.status})
    if(ad?.status == true) {
      setStatus({value: "true", label: "Active"})
    } else {
      setStatus({value: "false", label: "Inactive"})
    }
}, [ad])
useEffect(()=> {
  if(editAdSuccess) {
    Navigate("/ads")
  }
},[editAdSuccess])

    const Navigate = useNavigate()
    return (
      <div className='editAd'>
        {editAdLoading && <Loader fullScreen={true}/>}
       <div className='title'>Ads</div>
      <div className="Ads"> 
        <div className="editAd-container">
          <div className="editAd_title"><img src={bag}></img>edit New Ad</div>
          <div className='go-back' onClick={() => Navigate("/ads")}> {"< Go Back"}</div>
          <div className="editAd_form">
            <div className='form'>
        <div className='link-tiers'>
          <Input type="text" placeholder="Ad Link" title={"Ad Link"} style={{width:"50%"}} onChange={(e) => setAd({...Ad, link: e.target.value})} value={Ad?.link}></Input>
          <DropDown title={"Status"} placeholder={"select Status"} data={[{label:"Active", value: true}, {label:"Inactive", value: false}]} setValue={(e) => setAd({...Ad, status: e})} value={status} style={{width:"50%"}}></DropDown>
        </div>
        <div className='banner-cover'>
          <FileInput title1={"Banner"} title2={"Recommended Ration : 16:9"} header={"Upload Banner"} width={"50%"} onChange={(e) => setAd({...Ad, banner_image: e.target.files[0]})} accept={".jpg, .jpeg, .png"} value={Ad?.banner_image} ></FileInput>
          <FileInput title1={"Cover"} title2={"Recommended Ration : 16:9"} header={"Upload Cover"} width={"50%"} onChange={(e) => setAd({...Ad, image: e.target.files[0]})} accept={".jpg, .jpeg, .png"} value={Ad?.image}></FileInput>
        </div>
        <div className='editAd_btn'>
          <Button main={true} width={"15rem"} onClick={()=>{handleEdit()}} loading={editAdLoading}>Save</Button>
        </div>
        {error && <div className='error'>{error}</div>}
        </div>
          </div>
        </div>
        </div></div>
    )
  }

export default EditAd
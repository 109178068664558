import React from "react";
import "./TableHeader.scss";

function TableHeader({ children, style, variant }) {
  return (
    <thead
      className={
        variant ? "table_header_main variant_header" : "table_header_main"
      }
      style={style}
    >
      {children}
    </thead>
  );
}

export default TableHeader;
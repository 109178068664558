import React, { lazy } from "react";
import { Routes as Routers, Route } from "react-router-dom";
import CreateAd from "./Pages/AdsPage/AdsActions/CreateAd/CreateAd";
import EditAd from "./Pages/AdsPage/AdsActions/EditAd/EditAd";
const ViewPage = lazy(() => import("./Pages/ViewPage/ViewPage"));
const Ads = lazy(() => import("./Pages/AdsPage/Ads"));
const Home = lazy(() => import("./Pages/Home/Home"));

function AllRoutes() {
  return (
    <Routers>
      <Route path="/" element={<Ads />} />
      <Route path="/ads" element={<Ads />} />
      <Route path="/ads/create-ad" element={<CreateAd />} />
      <Route path="/ads/edit-ad" element={<EditAd />} />
    </Routers>
  );
}

export default AllRoutes;

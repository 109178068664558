import React from "react";
import "./Button.scss";
import Loader from "../Loader/Loader";

function Button({
  children,
  type,
  onClick,
  disabled,
  style,
  main,
  width,
  leftData,
  rightData,
  bottomBorder,
  loading,
  white,
}) {
  //main=true for red background and white text
  //main=false for white background and red text
  //only pass leftdata and rightdata when type is order
  //left data is number and right data is string
  // pass bottomborder true to get only bottom border to the button

  if (typeof main !== "boolean" || (main !== true && main !== false)) {
    throw new Error(
      `Invalid main ${main} of type ${typeof main}. Must be a boolean value of true or false.`
    );
  }
  if (!children || children === "") {
    throw new Error(`'${children}' is not a valid child.`);
  }

  if (type === "order") {
    main = true;
    if (!leftData || !rightData || leftData === "" || rightData === "") {
      throw new Error(
        "leftData and rightData props must not be empty for button type 'order'."
      );
    }
    if (typeof leftData !== "number" || typeof rightData !== "string") {
      throw new Error(
        "leftData prop must be of type number and rightData prop must be of type string."
      );
    }
  }
  if (bottomBorder) {
    main = false;
    if (typeof bottomBorder !== "boolean")
      throw new Error(
        `Invalid bottomBorder ${bottomBorder} of type ${typeof bottomBorder}. Must be a boolean value of true or false.`
      );
  }

  return (
    <button
      style={{
        backgroundColor: main ? "var(--secondary)" : "white",
        color: main ? "white" : "var(--secondary)",
        width: width ? width : "auto",
        display: type === "order" && "flex",
        justifyContent: type === "order" && "space-between",
        alignItems: type === "order" && "center",
        padding: type === "order" && "5px 7px",
        ...style,
      }}
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={
        disabled
          ? "Main_button disabled_Main_button"
          : !bottomBorder
          ? "Main_button"
          : "Main_button Main_button_bottom_border"
      }
    >
      {type === "order" && <div className="left_data">{leftData}</div>}
      {!loading ? (
        children
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader onlyDots={true} white />
        </div>
      )}
      {type === "order" && <div className="right_data">{rightData}</div>}
    </button>
  );
}

export default Button;

import React from "react";
import "./Loader.scss";
import logoSmall from "../../assets/images/logoSmall.svg";
import smalLogoWhite from "../../assets/images/smalLogoWhite.svg";

function Loader({ fullScreen, onlyDots, white }) {
  if (!fullScreen) {
    return (
      <>
        {!onlyDots ? (
          <div className="Loader_Main">
            <div className="Loader_Text">Loading</div>
            <div className="bouncy-loader">
              <img src={white ? smalLogoWhite : logoSmall} className="dot" />
              <img
                src={white ? smalLogoWhite : logoSmall}
                className="dot"
              />{" "}
              <img src={white ? smalLogoWhite : logoSmall} className="dot" />
            </div>
          </div>
        ) : (
          <div className="bouncy-loader">
            <img src={white ? smalLogoWhite : logoSmall} className="dot2" />
            <img src={white ? smalLogoWhite : logoSmall} className="dot2" />
            <img src={white ? smalLogoWhite : logoSmall} className="dot2" />
          </div>
        )}
      </>
    );
  } else {
    return (
      <div className="Loader_Main_fullScreen">
        <div className="Loader_Main_Background"></div>
        <div className="Loader_Main_Foreground">
          {!onlyDots && <div className="Loader_Text">Loading</div>}
          <div className="bouncy-loader">
            <img src={white ? smalLogoWhite : logoSmall} className="dot" />
            <img src={white ? smalLogoWhite : logoSmall} className="dot" />{" "}
            <img src={white ? smalLogoWhite : logoSmall} className="dot" />
          </div>
        </div>
      </div>
    );
  }
}

export default Loader;

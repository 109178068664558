import React, { useEffect, useState } from "react";
import "./Login.scss";
import Input from "../../Components/Input/Input";
import Button from "../../Components/Button/Button";
import img from "../../assets/images/login.svg";
import { forgetPassword, login } from "../../Redux/Auth/authActions";
import { useDispatch, useSelector } from "react-redux";

function Login() {
  const [resetClicked, setResetClicked] = useState(false);

  const [data, setdata] = useState({
    email: "",
    password: "",
  });

  const dispatch = useDispatch();

  const handleLogin = (e) => {
    e.preventDefault();
    if (!resetClicked) dispatch(login(data));
    else dispatch(forgetPassword(data.email));
  };

  const {
    loginSuccess,
    loginError,
    loginLoading,
    forgetPasswordSuccess,
    forgetPasswordError,
    forgetPasswordLoading,
  } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (forgetPasswordSuccess) {
      setResetClicked(false);
    }
  }, [forgetPasswordSuccess]);

  return (
    <div className="login_main">
      <div className="login_form">
        {resetClicked && (
          <div
            className="go_back"
            onClick={() => setResetClicked(!resetClicked)}
          >
            &lt; Go Back
          </div>
        )}
        <div className="title">Welcome!</div>
        <div className="sub_title">
          {resetClicked
            ? "Type your email to reset your password"
            : "Log in to your account to conitnue"}
        </div>
        <form className="inputs" onSubmit={handleLogin}>
          <Input
            placeholder={"Email"}
            type={"email"}
            title={
              <div style={{ fontFamily: "P_Medium", fontSize: "0.9rem" }}>
                Email Address
              </div>
            }
            required
            value={data?.email}
            onChange={(e) => setdata({ ...data, email: e.target.value })}
          />
          <br />
          {!resetClicked && (
            <Input
              placeholder={"Password"}
              type={"password"}
              title={
                <div style={{ fontFamily: "P_Medium", fontSize: "0.9rem" }}>
                  Password
                </div>
              }
              required
              value={data?.password}
              onChange={(e) => setdata({ ...data, password: e.target.value })}
            />
          )}
          {!resetClicked && (
            <div
              className="forget_password"
              onClick={() => setResetClicked(!resetClicked)}
            >
              Forget Password
            </div>
          )}

          <div
            className={
              resetClicked ? "submit_button no_margin_top" : "submit_button"
            }
          >
            <Button
              style={{
                backgroundColor: `rgba(219,43,31,0.15)`,
                borderColor: "transparent",
                color: "var(--accent)",
              }}
              main={false}
              width={"100%"}
              loading={loginLoading || forgetPasswordLoading}
              disabled={loginLoading || forgetPasswordLoading}
            >
              {!resetClicked ? "Log In" : "Reset Password"}
            </Button>
          </div>
          {(loginError || forgetPasswordError) && (
            <div className="error" style={{ padding: "1rem 0" }}>
              {loginError || forgetPasswordError}
            </div>
          )}
        </form>
      </div>
      <div className="right_div">
        <img src={img} alt={"image"} />
      </div>
    </div>
  );
}

export default Login;

import { Suspense, useEffect } from "react";
import "./App.css";
import Loader from "./Components/Loader/Loader";
import { ToastContainer } from "react-toastify";
import AllRoutes from "./AllRoutes";
import Layout from "./Layout/Layout";
import { useSelector } from "react-redux";
import Login from "./Pages/Login/Login";

function App() {
  const { user } = useSelector((state) => state.authReducer);

  return (
    <>
      <ToastContainer />
      {Object.keys(user).length > 0 ? (
        <Layout>
          <Suspense fallback={<Loader fullScreen />}>
            <AllRoutes />
          </Suspense>
        </Layout>
      ) : (
        <Login />
      )}
    </>
  );
}

export default App;

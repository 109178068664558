import React from "react";
import "./TableBody.scss";
import Row from "../Row/Row";

function TableBody({ children, style, variant, hover }) {
  return (
    <tbody
      className={
        variant
          ? hover
            ? "table_body_main variant_2_table_body variant_2_table_body_hover"
            : "table_body_main variant_2_table_body"
          : hover
          ? "table_body_main table_body_main_hover"
          : "table_body_main"
      }
      style={style}
    >
      {children ? children : <div className="no_data">No data found</div>}
    </tbody>
  );
}

export default TableBody;

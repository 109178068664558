import React from "react";
import "./CustomPopup.scss";

function CustomPopup({ title, children, width, setShowPopup, show, onClose }) {
  return (
    <>
      {show && (
        <div className="popup_main">
          <div
            className="background"
            onClick={() => {
              onClose && onClose();
              setShowPopup(false);
            }}
          ></div>
          <div
            className={"popup_container form_container"}
            style={{ width: width }}
          >
            <div className="custom_popup_title">{title}</div>
            <div className="custom_popup_body">{children}</div>
          </div>
        </div>
      )}
    </>
  );
}

export default CustomPopup;

import React from "react";
import "./Row.scss";

function Row({ children, style, onClick, variant, red }) {
  return (
    <tr
      className={
        variant
          ? "row_main variant_2"
          : red
          ? "row_main red_Background"
          : "row_main"
      }
      style={style}
      onClick={onClick && onClick}
    >
      {children}
    </tr>
  );
}

export default Row;

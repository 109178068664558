import React from "react";
import "./Switch.scss";

function Switch({ checked, setChecked, disabled }) {
  const a = Math.random();

  return (
    <div className={"checkbox-wrapper-35"}>
      <label className={disabled ? "toggle-switch disabled" : "toggle-switch"}>
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) => {
            setChecked(e.target.checked);
          }}
          disabled={disabled}
        />
        <div className="toggle-switch-background">
          <div className="toggle-switch-handle"></div>
        </div>
      </label>
    </div>
  );
}

export default Switch;

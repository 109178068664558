import React from "react";
import "./FileInput.scss";
import { useRef } from "react";
import upload from "../../assets/images/upload.svg";
import uploaded from "../../assets/images/approved.svg";

function FileInput({
  value,
  onChange,
  accept,
  title1,
  title2,
  header,
  disabled,
  width,
  height,
  required,
}) {
  const ref = useRef(null);
  const handleClick = () => {
    ref.current.click();
  };
  return (
    <div
      className="file_input_main"
      style={{ cursor: disabled ? "default" : "pointer", width: width }}
    >
      <input
        style={{ display: "none" }}
        type="file"
        ref={ref}
        accept={accept}
        onChange={onChange}
        required={required}
      />
      {header && <div className="input_file_title">{header}</div>}
      <div
        className={
          disabled ? "input file_box file_box_disabled" : "input file_box"
        }
        onClick={() => {
          !disabled && handleClick();
        }}
        style={{ height: height }}
      >
        {!value ? (
          <img src={upload} alt="upload_icon" className="input_file_img" />
        ) : value?.type?.includes("image") ? (
          <img
            src={URL.createObjectURL(value)}
            alt="upload_icon"
            className="input_file_img2"
          />
        ) : (
          <img src={uploaded} alt="upload_icon" className="input_file_img" />
        )}
        <div className="inputfile_text">
          <div
            className={
              !disabled
                ? "inputfile_text_title"
                : "inputfile_text_title inputfile_text_title_disabled"
            }
          >
            {title1}
          </div>
          <div
            className={
              !disabled
                ? "inputfile_text_title2"
                : "inputfile_text_title2 inputfile_text_title2_disabled"
            }
          >
            {title2}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FileInput;

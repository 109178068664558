import { createSlice } from "@reduxjs/toolkit";

const intialState = {
  user: {},
  getUserLoading: false,
  getUserError: "",
  loginLoading: false,
  loginError: "",
  loginSuccess: false,
  forgetPasswordLoading: false,
  forgetPasswordError: "",
  forgetPasswordSuccess: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState: intialState,
  reducers: {
    getUserRequest: (state) => {
      state.getUserLoading = true;
      state.getUserError = "";
      state.user = {};
    },
    getUserSuccess: (state, action) => {
      state.getUserLoading = false;
      state.user = action.payload;
      state.getUserError = "";
    },
    getUserError: (state, action) => {
      state.getUserLoading = false;
      state.getUserError = action.payload;
      state.user = {};
    },
    //login
    loginRequest: (state) => {
      state.loginLoading = true;
      state.loginError = "";
      state.loginSuccess = false;
    },
    loginSuccess: (state, action) => {
      state.loginLoading = false;
      state.user = action.payload;
      state.loginError = "";
      state.loginSuccess = true;
    },
    loginError: (state, action) => {
      state.loginLoading = false;
      state.loginError = action.payload;
      state.loginSuccess = false;
    },
    //forget password
    forgetPasswordRequest: (state) => {
      state.forgetPasswordLoading = true;
      state.forgetPasswordError = "";
      state.forgetPasswordSuccess = false;
    },
    forgetPasswordSuccess: (state) => {
      state.forgetPasswordLoading = false;
      state.forgetPasswordError = "";
      state.forgetPasswordSuccess = true;
    },
    forgetPasswordError: (state, action) => {
      state.forgetPasswordLoading = false;
      state.forgetPasswordError = action.payload;
      state.forgetPasswordSuccess = false;
    },

    //clear
    clear: (state) => {
      state.loginError = "";
      state.loginSuccess = false;
      state.forgetPasswordError = "";
      state.forgetPasswordSuccess = false;
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;

import React from "react";
import "./Table.scss";

function Table({ title, children, style, variant, small }) {
  return (
    <div
      className={variant ? "table_container table_variant" : "table_container"}
    >
      {title && <div className="table_title">{title}</div>}
      <table
        className={small ? "table_main small_table" : "table_main"}
        style={style}
      >
        {children}
      </table>
    </div>
  );
}

export default Table;

import React, { useEffect, useRef, useState } from "react";
import "./Layout.scss";
import bytelink_logo from "../assets/images/bytelink_logo.svg";
import { RxHamburgerMenu } from "react-icons/rx";
import gsap from "gsap";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function Layout({ children }) {
  const [selected, setSelected] = useState("home");
  const [open, setOpen] = useState(false);

  const divRef = useRef(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    setSelected(pathname.split("/")[1]);
  }, [pathname]);

  const showIcon = () => {
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    if (window.innerWidth < 768) {
      if (open) {
        divRef.current.focus();
        gsap.to(".Main_body_left", {
          duration: 0.4,
          transform: "translateX(0%)",
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
        });
        gsap.to(".burger_icon", { duration: 0.4, marginLeft: "20rem" });
      } else {
        gsap.to(".Main_body_left", {
          duration: 0.4,
          transform: "translateX(-22rem)",
          boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.2)",
        });
        gsap.to(".burger_icon", { duration: 0.4, marginLeft: "0rem" });
      }
    }
  }, [open]);

  const data = [
    {
      label: "Ads",
      path: "/ads",
    },
  ];

  return (
    <div className="Main_body">
      <Helmet>
        <title>
          {selected?.charAt(0)?.toLocaleUpperCase() + selected?.slice(1)}
        </title>
      </Helmet>
      <div
        className="Main_body_left"
        tabIndex="0" // Make the div focusable
        ref={divRef}
        onBlur={() => {
          setOpen(false);
        }}
      >
        <div className="Main_body_left_div">
          <div className="Main_body_left_div_top">
            <img
              src={bytelink_logo}
              alt={"byte_link_logo"}
              className="bytelink_logo"
              onClick={() => {
                setSelected("");
                navigate("/");
              }}
            />
            <div className="main_data">
              {data?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={
                      selected === item.label.toLowerCase() ||
                      (selected === "" && index === 0)
                        ? "single_item selected"
                        : "single_item"
                    }
                    onClick={() => {
                      setSelected(item.label.toLowerCase());
                      navigate(item.path);
                    }}
                  >
                    {item.label}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="Main_body_left_div_bottom">
            <div className="restaurant_details">
              <img src={""} alt={"Restaurant_logo"} className="res_logo" />
              <div className="res_detials">
                <div className="res_name">rest name</div>
                <div className="role">user role</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Main_body_right">
        <div className="Main_body_right_div">
          <RxHamburgerMenu className="burger_icon" onClick={showIcon} />
          {children}
        </div>
      </div>
    </div>
  );
}

export default Layout;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getAdsLoading: false,
    getAdsError: "",
    getAdsSuccess: false,
    getAdsData: [],
    createAdLoading: false,
    createAdError: "",
    createAdSuccess: false,
    editAdLoading: false,
    editAdError: "",
    editAdSuccess: false,
    deleteAdLoading: false,
    deleteAdError: "",
    deleteAdSuccess: false,
    adById:[],
    getAdByIdLoading: false,
    getAdByIdError: "",
    getAdByIdSuccess: false
};

const adsSlice = createSlice({
    name: "ads",
    initialState: initialState,
    reducers: {
        getAdsRequest: (state) => {
            state.getAdsLoading = true;
            state.getAdsError = "";
            state.getAdsSuccess = false;
        },
        getAdsSuccess: (state, action) => {
            state.getAdsLoading = false;
            state.getAdsData = action.payload;
            state.getAdsError = "";
            state.getAdsSuccess = true;
        },
        getAdsError: (state, action) => {
            state.getAdsLoading = false;
            state.getAdsError = action.payload;
            state.getAdsSuccess = false;
        },

        createAdRequest: (state) => {
            state.createAdLoading = true;
            state.createAdError = "";
            state.createAdSuccess = false;
    },

    createAdSuccess: (state, action) => {
        state.createAdLoading = false;
        state.createAdError = "";
        state.createAdSuccess = true;
    },

    createAdError: (state, action) => {
        state.createAdLoading = false;
        state.createAdError = action.payload;
        state.createAdSuccess = false;
    },
    editAdRequest: (state) => {
        state.editAdLoading = true;
        state.editAdError = "";
        state.editAdSuccess = false;
    },
    editAdSuccess: (state, action) => {
        state.editAdLoading = false;
        state.editAdError = "";
        state.editAdSuccess = true;
    },
    editAdError: (state, action) => {
        state.editAdLoading = false;
        state.editAdError = action.payload;
        state.editAdSuccess = false;
    },
    deleteAdRequest: (state) => {
        state.deleteAdLoading = true;
        state.deleteAdError = "";
        state.deleteAdSuccess = false;
    },

    deleteAdSuccess: (state, action) => {
        state.deleteAdLoading = false;
        state.deleteAdError = ""; 
        state.deleteAdSuccess = true;
},

deleteAdError: (state, action) => { 
    state.deleteAdLoading = false;
    state.deleteAdError = action.payload;
    state.deleteAdSuccess = false;
},

getAdByIdRequest: (state) => {
    state.getAdByIdLoading = true;
    state.getAdByIdError = "";
    state.getAdByIdSuccess = false;
},
getAdByIdSuccess: (state, action) => {
    state.getAdByIdLoading = false;
    state.adById = action.payload;
    state.getAdByIdError = "";
    state.getAdByIdSuccess = true;
},
getAdByIdFailure: (state, action) => {
    state.getAdByIdLoading = false;
    state.getAdByIdError = action.payload;
    state.getAdByIdSuccess = false;
},
clearAllStates: (state) => {
    state.getAdsLoading = false;
    state.getAdsError = "";
    state.getAdsSuccess = false;
    state.createAdLoading = false;
    state.createAdError = "";
    state.createAdSuccess = false;
    state.editAdLoading = false;
    state.editAdError = "";
    state.editAdSuccess = false;
    state.deleteAdLoading = false;
    state.deleteAdError = "";
    state.deleteAdSuccess = false;
    state.getAdByIdLoading = false;
    state.getAdByIdError = "";
    state.getAdByIdSuccess = false;
}
    }
});

export const AdsActions = adsSlice.actions;
export default adsSlice.reducer;
import React, { useRef } from "react";
import "./Input.scss";

function Input({
  type,
  placeholder,
  value,
  onChange,
  style,
  disabled,
  required,
  title,
  sideData,
  onClick,
  onblur,
}) {
  //pass sideData prop to add icon or % or currency or anything else
  if (!type || type === "") {
    throw new Error(`Prop type is required.`);
  }

  const input_ref = useRef(null);

  return (
    <div style={style} className="input_main">
      {title && <div className="input_title">{title}</div>}
      <div className="input_field" onClick={() => input_ref.current.focus()}>
        <input
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          required={required}
          style={{ width: "100%" }}
          value={value || ""}
          className={
            disabled ? "input_input input_input_disabled" : "input_input"
          }
          ref={input_ref}
          onClick={onClick}
          onBlur={onblur}
        />
        {sideData && <div className="input_side_data">{sideData}</div>}
      </div>
    </div>
  );
}

export default Input;

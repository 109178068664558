import React, { useEffect, useState } from "react";
import Button from "../../../../Components/Button/Button";
import "./CreateAd.scss";
import bag from "../../../../assets/images/shopping-bag.svg";
import Input from "../../../../Components/Input/Input";
import FileInput from "../../../../Components/FileInput/FileInput";
import { useNavigate } from "react-router-dom";
import { createAdAction } from "../../../../Redux/Ads/AdsActions";
import { useDispatch } from "react-redux";
import Ads from "../../Ads";
import { useSelector } from "react-redux";
import Loader from "../../../../Components/Loader/Loader";
import CustomDropDown from "../../../../Components/CustomDropDown/CustomDropDown";
import DropDown from "../../../../Components/DropDown/DropDown";

function CreateAd() {
  const { createAdSuccess, createAdLoading, createAdError } = useSelector(
    (state) => state.adsReducer
  );
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [Ad, setAd] = useState({
    link: "",
    Tiers: "",
    banner_image: "",
    image: "",
    status: null,
  });

  const handleAd = () => {
    dispatch(
      createAdAction({
        link: Ad.link,
        banner_image: Ad.banner_image,
        image: Ad.image,
        status: Ad.status?.value,
      })
    );
    setError(createAdError);
    if (createAdSuccess) {
      setAd({
        link: "",
        Tiers: "",
        banner_image: "",
        image: "",
        status: null,
      });
    }
  };

  useEffect(() => {
    if (createAdSuccess) {
      Navigate("/ads");
    }
  }, [createAdSuccess]);

  useEffect(() => {
    setError("");
  }, []);
  const Navigate = useNavigate();
  return (
    <div className="createAd">
      {createAdLoading && <Loader fullScreen={true} />}
      <div className="title">Ads</div>
      <div className="Ads">
        <div className="createAd-container">
          <div className="createAd_title">
            <img src={bag}></img>Create New Ad
          </div>
          <div
            className="go-back"
            style={{ width: "fit-content" }}
            onClick={() => Navigate("/ads")}
          >
            {" "}
            {"< Go Back"}
          </div>
          <div className="createAd_form">
            <div className="form">
              <div className="link-tiers">
                <Input
                  type="text"
                  placeholder="ex: https://google.com"
                  title={"Ad Link"}
                  style={{ width: "50%" }}
                  onChange={(e) => setAd({ ...Ad, link: e.target.value })}
                  value={Ad.link}
                  onblur={(e) => {
                    if (
                      !e.target.value.includes("https://") &&
                      e.target.value != ""
                    ) {
                      setAd({ ...Ad, link: "https://" + e.target.value });
                    }
                  }}
                ></Input>
                <DropDown
                  title={"Status"}
                  placeholder={"select Status"}
                  data={[
                    { label: "Active", value: true },
                    { label: "Inactive", value: false },
                  ]}
                  setValue={(e) => setAd({ ...Ad, status: e })}
                  value={Ad?.status}
                  style={{ width: "50%" }}
                ></DropDown>
              </div>
              <div className="banner-cover">
                <FileInput
                  title1={"Banner"}
                  title2={"Recommended Ration : 16:9"}
                  header={"Upload Banner"}
                  width={"50%"}
                  onChange={(e) =>
                    setAd({ ...Ad, banner_image: e.target.files[0] })
                  }
                  accept={".jpg, .jpeg, .png"}
                  value={Ad.banner_image}
                ></FileInput>
                <FileInput
                  title1={"Cover"}
                  title2={"Recommended Ration : 16:9"}
                  header={"Upload Cover"}
                  width={"50%"}
                  onChange={(e) => setAd({ ...Ad, image: e.target.files[0] })}
                  accept={".jpg, .jpeg, .png"}
                  value={Ad.image}
                ></FileInput>
              </div>
              <div className="createAd_btn">
                <Button
                  main={true}
                  width={"15rem"}
                  onClick={() => {
                    handleAd();
                  }}
                  loading={createAdLoading}
                >
                  Save
                </Button>
              </div>
              {error && <div className="error">{error}</div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateAd;
